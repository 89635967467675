import '../css/styles.css'
const Parse = require('parse');

// This sets up the parse server 
const STORAGE_ROOT = 'fnsz_app_last_';
const SERVER_URL = 'https://funsizeapps.b4a.app/';
const PEVENTS_ENDPOINT = 'getPersonalEvents';
const CEVENTS_ENDPOINT = 'getCompanyEvents';
const APP_ID = 'rHwkgreMFdjMqUGu9EoemDE4YMomVNLQwIX9Czr9';
const JAVASCRIPT_KEY = 'xdllsMsa2dAhWFuovVzyJTj7OIZmzLMeo5vsEFYv';
Parse.initialize(APP_ID, JAVASCRIPT_KEY);
Parse.serverURL = SERVER_URL;

var funsizer;

window.addEventListener('load', () => {
  gsap.to(document.getElementsByClassName('loader-container')[0], {opacity: 1, duration: 0.25});
  gsap.to(document.getElementsByClassName('loader')[0], {scale: 0.5, duration: 1, ease: "bounce.out", repeat: -1, yoyo: true});
  document.getElementById('btn_logout').addEventListener('click', ()=>{
    Parse.User.logOut().then(() => {
      initFunsizer(); 
    });
  });
  initFunsizer(); 
  const hiddens = document.querySelector('nav').getElementsByClassName('hidden');
    for(var i = 0; i < hiddens.length; i++){
      gsap.to(hiddens[i], {opacity: 1, duration: 0.35, delay: 0.2*i});
    }
});

const initFunsizer=()=>{
    funsizer = Parse.User.current();
    if (funsizer) {
      getIOTD().then((img) => {
        gsap.to(document.getElementsByClassName('loader-container')[0], {opacity: 0, duration: 0.25});
        document.getElementById('iotd_title').innerHTML = img.Title;
        document.getElementById('iotd_subtitle').innerHTML = img.Description;
        document.getElementById('iotd_image').src = img.Image.url;
        const hiddens = document.getElementsByClassName('hidden');
        for(var i = 0; i < hiddens.length; i++){
          gsap.to(hiddens[i], {opacity: 1, duration: 0.35, delay: 0.2*i});
        }
      }).catch(function(e){  
        handleParseError('Admin Error When Getting IOTD', e);
      });
    } else {
      window.location = `${window.location.origin}/${(window.location.pathname.includes("staging") ? "staging/" : "")}login.html?redirect=${window.location.pathname}`;
    }
}

 /**
  * IOTD Calls
  */

  const getIOTD = async () => {
    if(!shouldLoadIOTDFromAPI()) {
      const iotd = localStorage.getObject(`${STORAGE_ROOT}iotd_data`);
      return iotd;
    }
    try {
      const image = await Parse.Cloud.run('imageOfTheDay');
      const d = new Date();
      const day = d.getDay();
      localStorage.setObject(`${STORAGE_ROOT}iotd_load`, day);
      localStorage.setObject(`${STORAGE_ROOT}iotd_data`, image);
      return localStorage.getObject(`${STORAGE_ROOT}iotd_data`);
    } catch (error) {
        throw error;      
    }
 }

 const shouldLoadIOTDFromAPI=()=>{
  const data = localStorage.getObject(`${STORAGE_ROOT}iotd_data`);
  const d = new Date();
  const day = d.getDay();
  const lastDay = localStorage.getObject(`${STORAGE_ROOT}iotd_load`);
  if(lastDay == null || data == null) { 
    localStorage.setObject(`${STORAGE_ROOT}iotd_load`, day); 
    return true;
  }
  return day != lastDay; // only refresh if a new day
 }

 function handleParseError(msg, err) {
  switch (err.code) {
    case Parse.Error.INVALID_SESSION_TOKEN:
      alert('Invalid session token.');
      Parse.User.logOut();
      window.location = `${window.location.origin}/${(window.location.pathname.includes("staging") ? "staging/" : "")}login.html?redirect=${window.location.pathname}`;
      break;
    default:
      console.log(`${msg} : ${err.code} - ${err.message}`);
  }
}

Storage.prototype.setObject = function(key, value) {
  this.setItem(key, JSON.stringify(value));
}

Storage.prototype.getObject = function(key) {
  var value = this.getItem(key);
  return value && JSON.parse(value);
}